
const getEnvironmentVariable = name => {
    const value = process.env[name]

    if (value === undefined) {
        throw new Error(`Key ${name} is not defined in environment`)
    } else if (value === '') {
        throw new Error(`Key ${name} is defined in environment as an empty string`)
    }
    return value
}

export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_PRONTO_AUTH0_DOMAIN')
export const AUTH0_CLIENT_ID = getEnvironmentVariable('REACT_APP_PRONTO_AUTH0_CLIENT_ID')

export const __PROD__ = 'production'
export const __ACCEPTANCE__ = 'accp'
export const __TEST__ = 'base'
// export type DeploymentEnvironment = typeof __PROD__ | typeof __TEST__ | typeof __ACCEPTANCE__

// Like the inlined variable during the compile step but 'visible' for TS
// https://reactjs.org/docs/codebase-overview.html#development-and-production
export const __DEV__ = process.env.NODE_ENV === 'development'