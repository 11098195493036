import * as React from 'react';
import './Loader.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export default function Loader() {
    const loaderCss = 'loaderStyle';
    return (
        <div>
            <Box
                className={loaderCss}
            >
                <CircularProgress />
            </Box>
        </div>
    );
}