import React, { useState, useCallback } from 'react';
import './Main.css';
import { useAuth } from '../AuthProvider/AuthContext';
import { Layout, Flex } from 'antd';
import { Table } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventFilter } from '../redux/actions/EventFilter.actions';
import dayjs from 'dayjs';
import Loader from '../Common/Loader';
import MainHeader from './MainHeader';
import FilterComponent from './FilterComponent';
import * as XLSX from 'xlsx';
import {getOrganizationNamesList} from "../redux/actions/OrganizationNames.actions";
import {tokenStorage} from "../AuthProvider/TokenStorage";

const Main = (props) => {
  const { getOrganizationNames, getEventFilter } = props;
  const auth = useAuth();
  const [mandatoryFlag, setMandatoryFlag] = React.useState(false);
  const [currentAppIndex, setCurrentAppIndex] = React.useState(0);
  const [dataDetail, setDataDetail] = React.useState({
    fromDate: dayjs(new Date()).subtract(180, 'days'),
    requiredFromDate: false,
    toDate: dayjs(new Date()),
    requiredToDate: false,
    companiesList: [],
    listOfAppIds: [],
    listOfAppNames: [],
    listOfPorts: [],
    selectedCompanies: [],
    appId: '',
    appName: '',
    port: 'USHOU'
  });

  const getCompaniesList = useCallback(() => {
    const companies = props.eventFilter[0]?.companies;
    let newState = dataDetail;
    companies?.forEach((item) => {
        newState?.companiesList.push(item);
    });
    setDataDetail(newState);
}, [props.eventFilter, dataDetail]);

const getPorts = useCallback(() => {
    const portsList = props.eventFilter[0]?.ports;
    let newState = dataDetail;
    portsList?.forEach((item) => {
        newState?.listOfPorts.push(item);
    });
    setDataDetail(newState);
}, [props.eventFilter,dataDetail]);

const getAppIdsList = useCallback(() => {
    const appIds = props.eventFilter?.map((item) => item.appId);
    const appNames = props.eventFilter?.map((item) => item.appName);
    let newState = dataDetail;
    newState['appId'] = props.eventFilter[0]?.appId;
    newState['appName'] = props.eventFilter[0]?.appName;
    appIds?.forEach((item) => {
      newState?.listOfAppIds.push(item);
    });
    appNames?.forEach((item) => {
      newState?.listOfAppNames.push(item);
    });
    setCurrentAppIndex(0);
    setDataDetail(newState);
}, [props.eventFilter, dataDetail]);


  useEffect(() => {
    if (tokenStorage.getToken() || auth?.token) {
      getEventFilter(auth.token);
    }
  }, [getEventFilter, auth.token]);

  useEffect(() => {
    const newAppIds = new Set(props.eventFilter?.map((item) => item.appId));
    const existingAppIds = new Set(dataDetail?.listOfAppIds);

    if (newAppIds.size !== existingAppIds.size) {
      getAppIdsList();
      getCompaniesList();
      getPorts();
    }

    if (dataDetail?.appId && dataDetail?.fromDate && dataDetail?.toDate) {
      getOrganizationNames(dataDetail);
    }
  }, [dataDetail, getOrganizationNames, auth.token, getAppIdsList, getCompaniesList, getPorts, props.eventFilter]);
  const [error, setError] = React.useState('');
  const onChangeData = (title, value) => {
    let newState = dataDetail;
    let stringvalue = '';
    if (title === 'fromDate') {
      newState['fromDate'] = value;
      if (dayjs(value) > dayjs(newState['toDate'])) {
        newState['requiredFromDate'] = true;
        newState['requiredToDate'] = false;
        stringvalue = 'From date cannot be later than To date';
      } else {
        newState['requiredFromDate'] = false;
        newState['requiredToDate'] = false;
      }
    } else if (title === 'toDate') {
      newState['toDate'] = value;
      if (dayjs(newState['fromDate']) > dayjs(value)) {
        newState['requiredToDate'] = true;
        newState['requiredFromDate'] = false;
        stringvalue = 'To date cannot be lesser than from date';
      } else {
        newState['requiredToDate'] = false;
        newState['requiredFromDate'] = false;
      }
    }
    setDataDetail(newState);
    setError(stringvalue);
  };
  const handleDropdownChange = (event, data, type) => {
    let newState = dataDetail;
    if (type === "companies") {
      if (newState?.selectedCompanies?.includes(data?.optionValue)) {
        newState['selectedCompanies'] = newState?.selectedCompanies?.filter(
          (item) => item !== data?.optionValue
        );
      } else {
        newState?.selectedCompanies.push(data?.optionValue);
      }
      setDataDetail(newState);
    } else if (type === "ports") {
      if (data?.optionValue === 'Select All') {
        newState['port'] = '';
        setDataDetail(newState);
      } else {
        newState['port'] = data?.optionValue;
        setDataDetail(newState);
      }
    } else if (type === "appId") {
      newState['appName'] = data?.optionText;
      newState['appId'] = data?.optionValue;
      const index = props?.eventFilter?.map((obj) => obj.appId).indexOf(data?.optionValue);
      newState['companiesList'] = props?.eventFilter[index]?.companies;
      newState['listOfAppIds'] = props?.eventFilter?.map((item) => item.appId);
      newState['listOfAppNames'] = props?.eventFilter?.map((item) => item.appName);
      newState['listOfPorts'] = props?.eventFilter[index]?.ports;
      newState['selectedCompanies'] = [];
      newState['port'] = '';
      setCurrentAppIndex(index);
      setDataDetail(newState);
    }
  };
  const getDataSorceRows = (arrayData) => {
    const resultArray = arrayData?.map((item, index) => {
      return { ...item, id: `${index}` };
    })
    return resultArray;
  };

  const onSubmit = () => {
    let newState = dataDetail;
    if (newState?.fromDate && newState?.toDate && newState?.requiredFromDate === false && newState?.requiredToDate === false) {
      let newObj = {
        fromDate: dataDetail?.fromDate,
        toDate: dataDetail?.toDate,
        selectedCompanies: dataDetail?.selectedCompanies,
        appId: dataDetail?.appId,
        appName: dataDetail?.appName,
        port:dataDetail?.port,
      }
      props?.getOrganizationNames(newObj, auth?.token)
      setMandatoryFlag(false);
      setFilterCollapse(false);
      setIsFilteredRowsFlag(false);
      setFilteredInfo({});
      setSortedInfo({});
      setFilteredDataSource([]);
    } else {
      setMandatoryFlag(true);
    }
  };
  const onLogout = () => {
    console.log("LOGGING OUT....");
    tokenStorage.deleteToken();
    auth.signOut();
  };
  const [collapsed, setCollapsed] = useState(false);
  const [filterCollapse, setFilterCollapse] = useState(false);
  const [isFilteredRowsFlag, setIsFilteredRowsFlag] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [rowHeight, setRowHeight] = useState('large');
  const handleSizeChange = (value) => {
    setRowHeight(value?.key);
  };
  const handleCollapse = () => {
    setCollapsed(!collapsed)
  };
  const handleFilterCollapse = () => {
    setFilterCollapse(!filterCollapse);
  };
  const handleExportToCSV = () => {
    const xlData = (isFilteredRowsFlag === false) ? getDataSorceRows(props?.organizationNames ? props?.organizationNames : []) : filteredDataSource;
    console.log("Downloading " + xlData.length + " rows!");
    const ws = XLSX.utils.json_to_sheet(xlData);
    const csvData = XLSX.utils.sheet_to_csv(ws);

    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Save the Blob as a file (customize the filename)
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };
  const onClose = () => {
    setFilterCollapse(false);
  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter, extra) => {
    const isFilterReset = (filters?.company?.length > 0);
    setIsFilteredRowsFlag(isFilterReset);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setFilteredDataSource(extra?.currentDataSource);
  };

  const totalRows = () => {
    const rows = (isFilteredRowsFlag === false) ? getDataSorceRows(props?.organizationNames ? props?.organizationNames : []) : filteredDataSource;
    return rows?.length;
  }

  const columns = [
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',
      filteredValue: filteredInfo.organizationName || null,
      onFilter: (value, record) => record.organizationName.includes(value),
      sorter: (a, b) => a.organizationName.length - b.organizationName.length,
      sortOrder: sortedInfo.columnKey === 'organizationName' ? sortedInfo.order : null,
      ellipsis: true,
      width: '15%'
    },
    {
      title: 'No of Logins',
      dataIndex: 'loginCount',
      key: 'loginCount',
      filteredValue: filteredInfo.loginCount || null,
      onFilter: (value, record) => record.loginCount.includes(value),
      sorter: (a, b) => a.loginCount.length - b.loginCount.length,
      sortOrder: sortedInfo.columnKey === 'loginCount' ? sortedInfo.order : null,
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'No of Users',
      dataIndex: 'userCount',
      key: 'userCount',
      filteredValue: filteredInfo.userCount || null,
      onFilter: (value, record) => record.userCount.includes(value),
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Distinct Users',
      dataIndex: 'userNames',
      key: 'userNames',
      filteredValue: filteredInfo.userNames || null,
      onFilter: (value, record) => record.userNames.includes(value),
      ellipsis: true,
      width: '65%',
      render: (text) => (
          <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
            {text}
          </div>
      ),
    }
  ];
  return (
    <>
      <Flex>
        <Layout className="overflow-hidden">
          {props?.loading && <Loader />}
          <MainHeader onLogout={onLogout} collapsed={collapsed} rowHeight={rowHeight} filterCollapse={filterCollapse} handleSizeChange={handleSizeChange} handleCollapse={handleCollapse} handleFilterCollapse={handleFilterCollapse} handleExportToCSV={handleExportToCSV} totalRows={totalRows} />
          <Layout>
            {filterCollapse &&
              <FilterComponent onClose={onClose} currentAppIndex={currentAppIndex} collapsed={collapsed} filterCollapse={filterCollapse} dataDetail={dataDetail} onChangeData={onChangeData} mandatoryFlag={mandatoryFlag} error={error} handleDropdownChange={handleDropdownChange} onSubmit={onSubmit} />
            }
            <Layout >
              <Table columns={columns} className="minHeight" size={rowHeight} rowKey="id" dataSource={getDataSorceRows(props?.organizationNames ? props?.organizationNames : [])} ellipsis={true} onChange={handleChange} pagination={false} scroll={{ x: 100, y: "calc(100vh - 0px)" }} />
            </Layout>
          </Layout>
        </Layout>
      </Flex>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    eventFilter: state.eventFilterReducer.eventFilter,
    companyDetailsList: state.companyDetailsReducer.companyDetailsList,
    organizationNames: state.organizationNamesReducer.organizationNames,
    errorFlagEventFilter: state.eventFilterReducer.errorFlag,
    errorDetailsEventFilter: state.eventFilterReducer.errorDetails,
    errorFlag: state.companyDetailsReducer.errorFlag,
    errorDetails: state.companyDetailsReducer.errorDetails,
    loading: state.loadingReducer.loading.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getEventFilter: (token) => dispatch(getEventFilter(token)),
    getOrganizationNames: (dataDetail) => dispatch(getOrganizationNamesList(dataDetail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
