import {
    LOADER_STATE,
    ORGANIZATION_LIST,
    ORGANIZATION_LIST_ERROR
} from '../types';
import {PAGES} from "../../Constants/pages";
import dayjs from "dayjs";
import axiosInstance from "../../Axios/AxiosInstance";
import {tokenStorage} from "../../AuthProvider/TokenStorage";

export const getOrganizationNamesList = (dataDetail) => (dispatch) => {
    const token = tokenStorage.getToken();
    dispatch({type: LOADER_STATE, payload: {loading: true}});
    var payload = {
        fromDate: dayjs(dataDetail.fromDate).format("YYYY-MM-DD"),
        toDate: dayjs(dataDetail.toDate).format("YYYY-MM-DD"),
        appId: dataDetail?.appId,
    }
    if (dataDetail?.port) {
        payload = {...payload, port: dataDetail?.port};
    }

    if (dataDetail?.selectedCompanies?.length > 0) {
        payload = {...payload, companies: dataDetail?.selectedCompanies.join(",")}
    }

    // Using a promise to handle the asynchronous request
    return new Promise((resolve, reject) => {
        axiosInstance({
            method: 'get',
            url: PAGES.loginEventsSummaryEndpoint,
            params: payload, // Assuming payload contains query parameters
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                dispatch({
                    type: ORGANIZATION_LIST,
                    payload: res?.data
                })
                dispatch({
                    type: LOADER_STATE,
                    payload: {loading: false},
                })
                resolve(res.data); // Resolve the promise with the response data
            })
            .catch(e => {
                dispatch({
                    type: ORGANIZATION_LIST_ERROR,
                    payload: {
                        errorStatus: e?.response?.status ? e?.response?.status : '500',
                        errorFlag: true,
                        errorMessage: e?.message ? e?.message : 'Something went wrong please try again later!'
                    },
                })
                dispatch({
                    type: LOADER_STATE,
                    payload: {loading: false},
                })
                reject(e); // Reject the promise with the error
            });
    });
};

