import { ORGANIZATION_LIST_ERROR, ORGANIZATION_LIST} from '../types';

const initialState = {
  organizationNames:[],
  errorFlag: false,
  errorDetails: {},
}

const OrganizationNamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORGANIZATION_LIST:
           return {
            ...state,
               organizationNames:action.payload,
           };
        case ORGANIZATION_LIST_ERROR:
           return {
              ...state, 
              errorFlag: true,
              errorDetails: action.payload,
           };
         default: return state;
    }
};
export default OrganizationNamesReducer;