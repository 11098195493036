import * as React from 'react';
import './MainHeader.css';
import { Button, Layout } from 'antd';
import {DownOutlined, LogoutOutlined} from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import logopng1 from '../images/logopng1.png';
import {PAGES} from "../Constants/pages";

const { Header } = Layout;

function MainHeader(props) {
    const items = [
        {
            key: 'small',
            label: 'small',
        },
        {
            key: 'middle',
            label: 'middle',
        },
        {
            key: 'large',
            label: 'large',
        },
    ];
    const menuDropDown = 'menuDropDown';
    const totalEventsLabel = window.location.pathname === PAGES.details ? process.env.REACT_APP_LOGIN_EVENTS : process.env.REACT_APP_TOTAL_ROWS;
    return (
        <>
            <Header className="headerStyle">
                <div className="headerFlex menu">
                    <a href='/' className="headerButtonAnchor">
                        <img src={logopng1} alt="logo" href='/' height={30}/>
                        <h4 className="h4">{process.env.REACT_APP_APPLICATION_NAME}</h4>
                    </a>
                </div>
                <div className="marginLeft-auto borderLeft highlight menu">
                    <Button
                        type="text"
                        className="commonButton"
                    >{totalEventsLabel}{props?.totalRows()}</Button>
                </div>
                <div className="borderLeft menu">
                    <Button type="text" href={PAGES.root}
                            className="commonButton">{process.env.REACT_APP_HOME_LINK}</Button>
                </div>
                <div className="borderLeft menu">
                    <Button type="text" href={PAGES.details}
                            className="commonButton">{process.env.REACT_APP_DETAILS_LINK}</Button>
                </div>
                <div className="borderLeft menu">
                    <Button
                        type="text"
                        onClick={() => {
                            props?.handleExportToCSV();
                        }}
                        className="commonButton"
                    >{process.env.REACT_APP_DOWNLOAD}</Button>
                </div>
                <div className="menu">
                    <Dropdown
                        trigger={['click']}
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: [props?.rowHeight],
                            selectedKeys: [props?.rowHeight],
                            value: props?.rowHeight,
                            width: 500,
                            className: menuDropDown,
                            onSelect: (value) => {
                                props?.handleSizeChange(value)
                            },
                        }}
                        className='dropdownclass menu'
                    >

                        <button className="dropdownAnchor">
                            <Space>
                                {process.env.REACT_APP_ROW_HEIGHT}: {props?.rowHeight}
                                <DownOutlined/>
                            </Space>
                        </button>
                    </Dropdown>
                </div>
                <div className="menu">
                    <Button
                        type="text"
                        onClick={() => {
                            props?.handleFilterCollapse();
                        }}
                        className="commonButton borderRight"
                    >{process.env.REACT_APP_FILTER}</Button>
                </div>
                <div className="menu">
                    <Button
                        type="text"
                        className="commonButton borderRight"
                        title={process.env.REACT_APP_LOGOUT}
                        onClick={props?.onLogout}>
                        <LogoutOutlined/>
                    </Button>
                </div>
            </Header>
        </>
    );
}

export default MainHeader;