import React, {createContext, useContext} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { tokenStorage } from "./TokenStorage";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
    const [token, setToken] = React.useState(null);

    const signOut = () => {
        logout({ returnTo: window.location.origin });
    };

    React.useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const token = await getAccessTokenSilently();
                if (tokenStorage.getToken() !== token) {
                    tokenStorage.saveToken(token);
                    setToken(token);
                }
            } catch (error) {
                console.error('Error fetching access token:', error);
            }
        };

        if (isAuthenticated) {
            fetchAccessToken().then(r => console.log("Fetched access token"));
        }
    }, [isAuthenticated, getAccessTokenSilently]);


    return (
        <AuthContext.Provider value={{ token, user, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};
