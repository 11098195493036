import { COMPANIES_DETAIL_LIST, COMPANIES_DETAIL_LIST_ERROR } from '../types';

const initialState = {
  companyDetailsList:[],
  errorFlag: false,
  errorDetails: {},
}

const CompanyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANIES_DETAIL_LIST:
           return {
            ...state, 
            companyDetailsList:action.payload,
           };
        case COMPANIES_DETAIL_LIST_ERROR:
           return {
              ...state, 
              errorFlag: true,
              errorDetails: action.payload,
           };
         default: return state;
    }
};
export default CompanyDetailsReducer;