import { combineReducers } from 'redux';
import EventFilterReducer from './reducers/EventFilter.reducer'
import CompanyDetailsReducer from './reducers/CompanyDetails.reducer'
import LoaderReducer from './reducers/Loader.reducer';
import OrganizationNamesReducer from "./reducers/OrganizationNames.reducer";

const rootReducer = combineReducers({
    eventFilterReducer: EventFilterReducer,
    companyDetailsReducer: CompanyDetailsReducer,
    organizationNamesReducer: OrganizationNamesReducer,
    loadingReducer: LoaderReducer,
});
export default rootReducer;