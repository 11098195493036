import * as React from 'react';
import './FilterComponent.css';
import { Button } from 'antd';
import { Drawer } from 'antd';
import {
    Dropdown as DropdownFluent, Label, Option
} from "@fluentui/react-components";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {PAGES} from "../Constants/pages";


function FilterComponent(props) {
    const drawerClass = props?.collapsed ? 'special-drawer' : 'normal-drawer filterDrawer';
    const isDetailed = window.location.pathname === PAGES.details;

    const datepickerStyle = 'datepickerStyle';

    const extraBlock = (<>
        <div className='dropdownStyleFlex'>
            <Label className='fieldLable'>{process.env.REACT_APP_COMPANY}</Label>
            <DropdownFluent
                aria-labelledby={"1"}
                multiselect={true}
                placeholder="Select a Company"
                defaultSelectedOptions={props?.dataDetail?.selectedCompanies}
                onOptionSelect={(event, data) => {
                    props?.handleDropdownChange(event, data, "companies");
                }}
                className='dropdownStyle'
            >
                {props?.dataDetail?.companiesList?.map((option) => (
                    <Option key={option}>
                        {option}
                    </Option>
                ))}
            </DropdownFluent>
        </div>
    </>);


    return (
        <>
            <Drawer
                placement={'right'}
                width={400}
                className={drawerClass}
                onClose={props?.onClose}
                open={props?.filterCollapse}
                closable={false}
            >
                <Label className='tableFilterLable'>{process.env.REACT_APP_TABLE_FILTER}</Label>
                {/* Business wants to hide it */}
                {/*<div className='dropdownStyleFlex hide'>
                    <Label className='fieldLable'>{process.env.REACT_APP_APP_ID}</Label>
                    <DropdownFluent
                        aria-labelledby={"1"}
                        placeholder="Select a App Name"
                        defaultValue={props?.dataDetail?.appName}
                        onOptionSelect={(event, data) => {
                            props?.handleDropdownChange(event, data, "appId");
                        }}
                        className='dropdownStyle'
                    >
                        {props?.dataDetail?.listOfAppIds?.map((option, index) => (
                            <Option key={option}>
                                {props?.dataDetail?.listOfAppNames?.[index]}
                            </Option>
                        ))}
                    </DropdownFluent>
                </div>*/}
                <div className='flexdiv'>
                    <Label className='fieldLable'>{process.env.REACT_APP_FROM_DATE}</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker format='YYYY-MM-DD' slotProps={{
                            textField: {
                                variant: 'standard',
                                size: 'small',
                            },
                            field: {
                                shouldRespectLeadingZeros: true,
                                className: datepickerStyle
                            }
                        }}

                                    disableFuture
                                    value={props?.dataDetail?.fromDate}
                                    onChange={(value) => {
                                        props?.onChangeData('fromDate', value)
                                    }}/>
                        {props?.dataDetail?.requiredFromDate && (
                            <div className='textRed'>{props?.error}</div>
                        )}
                    </LocalizationProvider>
                    {props?.mandatoryFlag && props?.dataDetail?.requiredFromDate &&
                        <span className='spanTextStyle'>{process.env.REACT_APP_FROM_DATE_IS_REQUIRED}</span>}
                </div>
                <div className='flexdiv'>
                    <Label className='fieldLable'>{process.env.REACT_APP_TO_DATE}</Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker format='YYYY-MM-DD' slotProps={{
                            textField: {
                                variant: 'standard',
                                size: 'small',

                            },

                            field: {
                                shouldRespectLeadingZeros: true,
                                className: datepickerStyle
                            }
                        }}

                                    disableFuture
                                    value={props?.dataDetail?.toDate}
                                    onChange={(newValue) => {
                                        props?.onChangeData('toDate', newValue)
                                    }}

                        />
                        {props?.dataDetail?.requiredToDate && (
                            <div className='textRed'>{props?.error}</div>
                        )}
                    </LocalizationProvider>
                    {props?.mandatoryFlag && props?.dataDetail?.requiredToDate &&
                        <span className='spanTextStyle'>{process.env.REACT_APP_TO_DATE_IS_REQUIRED}</span>}
                </div>

                <div className='dropdownStyleFlex'>
                    <Label className='fieldLable'>{process.env.REACT_APP_PORT_NUMBER}</Label>
                    <DropdownFluent
                        aria-labelledby={"1"}
                        placeholder="Select a Port"
                        // multiselect={true}
                        defaultValue={props?.dataDetail?.port}
                        onOptionSelect={(event, data) => {
                            props?.handleDropdownChange(event, data, "ports");
                        }}
                        className='dropdownStyle'
                    >
                        <Option key='select_all'>Select All</Option>
                        {props?.dataDetail?.listOfPorts?.map((option) => (
                            <Option key={option}>
                                {option}
                            </Option>
                        ))}
                    </DropdownFluent>
                </div>

                {isDetailed ? extraBlock : ""}
                <div className='buttonFlexDiv'>
                    <Label className='extraFieldLable'></Label>
                    <Stack direction="column" spacing={2} className='maximumHeight'>
                        <Button variant="contained"
                                className='buttonApply'
                                onClick={() => {
                                    props?.onSubmit()
                                }}>
                            {process.env.REACT_APP_APPLY}
                        </Button>
                    </Stack>
                </div>
            </Drawer>
        </>
    );
}

export default FilterComponent;