import { GET_LOGIN_EVENTS_FILTERS, LOGIN_EVENTS_FILTERS_ERROR, LOADER_STATE } from '../types';
import axiosInstance from '../../Axios/AxiosInstance';
import { PAGES } from "../../Constants/pages";
import {tokenStorage} from "../../AuthProvider/TokenStorage";

export const getEventFilter = () => (dispatch) => {
    dispatch({ type: LOADER_STATE, payload: { loading: true } });
    // Using a promise to handle the asynchronous request
    return new Promise((resolve, reject) => {
        const token = tokenStorage.getToken();
        const handleResponse = (res) => {
            dispatch({ type: GET_LOGIN_EVENTS_FILTERS, payload: res?.data });
            dispatch({ type: LOADER_STATE, payload: { loading: false } });
            resolve(res.data); // Resolve the promise with the response data
        };

        const handleError = (e) => {
            dispatch({ type: LOADER_STATE, payload: { loading: false } });
            dispatch({
                type: LOGIN_EVENTS_FILTERS_ERROR,
                payload: {
                    errorStatus: e?.response?.status ? e?.response?.status : '500',
                    errorFlag: true,
                    errorMessage: e?.message ? e?.message : 'Something went wrong please try again later!'
                }
            });
            reject(e); // Reject the promise with the error
        }

        axiosInstance({
            method: 'get',
            url: PAGES.filtersEndPoint,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(handleResponse).catch(handleError);
    });
};


