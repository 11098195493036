import * as React from 'react';
import './ErrorPage.css';
import errorimage from '../images/errorimage.png'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function ErrorPage(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    // Access the data
    return (
        <div className='errorBlock'>
            <div className='errorFlex'>
                <div className='maxWidthBlock-28rem'>
                    <div className='errorStatusCode'>{props?.errorFlag ? (props?.errorStatus) : state?.errorStatus}</div>
                    <p
                        className='errorStatusStyle'
                    >Sorry we couldn't process your request something went wrong. </p>
                    <p className='marginBottom-1rem'><span className='fontWeightBold'>Message:</span> {props?.errorFlag ? (props?.errorMessage) : state?.errorMessage}</p>
                    <p className='marginBottom-1rem'>But dont worry, you can find plenty of other things on our homepage.</p>

                    {!props?.errorFlag &&<button className='backStyle' onClick={() => { navigate(props?.errorFlag ? (props?.errorRedirectComponent) : state?.errorRedirectComponent); }}>back to homepage</button>}
                </div>
                <div className='maxWidthBlock-32rem'>
                    <img src={errorimage} alt="error" height={600} />
                </div>

            </div>
        </div>
    );
}
