import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, __DEV__ } from '../config'

export const auth0Options = {
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin,
    audience: 'pronto-api',
    useRefreshTokens: true,
    cacheLocation: __DEV__ ? 'localstorage' : 'memory',
}
