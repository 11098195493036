import { LOADER_STATE } from '../types';

const initialState = {
    loading: false,
}

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER_STATE:
            return {
                ...state,
                loading: action.payload,
            };
        default: return state;
    }
};
export default LoaderReducer;