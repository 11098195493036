import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import logopng1 from '../images/logopng1.png';
import './Signin.css';
import { Flex } from 'antd';
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <button className="custom-login-button" onClick={() => loginWithRedirect()}><span>{process.env.REACT_APP_LOGIN}</span></button>;
};

export const Login = () => {
    return (
        <>
            <Flex>
                <CssBaseline />
                <div className="container">
                    <div className="header">
                        <a href={process.env.REACT_APP_APPLICATION_URL_HREF}>
                            <span className="back-arrow"></span><span>{process.env.REACT_APP_APPLICATION_URL}</span>
                        </a>
                    </div>
                    <div className="content">
                        <div className="login-background" />
                        <div className="login-container">
                            <div className="login-header">
                                <img src={logopng1} alt="logo"/>
                                <h4>{process.env.REACT_APP_APPLICATION_NAME}</h4>
                            </div>
                            <div className="login-content">
                                <h2 className="login-title">{process.env.REACT_APP_LOGIN}</h2>
                                <p className="login-paragraph">
                                    Welcome
                                    to {process.env.REACT_APP_APPLICATION_NAME}. {process.env.REACT_APP_APPLICATION_NAME} is
                                    a internal tool for administrative tasks.
                                </p>
                                <p className="login-paragraph">
                                    To have access you must have an account. If you don’t have an account yet, you can sign up here and request an account.
                                </p>
                                <LoginButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </Flex>
        </>
    );
}
