import './App.css';
import Main from './Components/Main';
import Details from './Components/DetailedView';
import ErrorPage from './Common/ErrorPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import { PAGES } from "./Constants/pages";

function App() {
  return (
      <Router>
        <Routes>
          <Route path={PAGES.error} element={<ErrorPage />} component={ErrorPage} />
          <Route path={PAGES.root} element={<Main />} />
          <Route path={PAGES.details} element={<Details />} />
        </Routes>
      </Router>
  );
}

export default App;
