import { GET_LOGIN_EVENTS_FILTERS, LOGIN_EVENTS_FILTERS_ERROR } from '../types';

const initialState = {
  eventFilter:[],
  errorFlag: false,
  errorDetails: {},
}

const EventFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGIN_EVENTS_FILTERS:
           return {
            ...state, 
            eventFilter:action.payload,
           };
        case LOGIN_EVENTS_FILTERS_ERROR:
           return {
              ...state, 
              errorFlag: true,
              errorDetails: action.payload,
           };
         default: return state;
    }
};
export default EventFilterReducer;