const AUTH_TOKEN_NAME = "token";
const AUTH_TOKEN_EXPIRES_AT = "expiresAt";


class TokenStorage {
    saveToken(token, expiresIn = 900) {
        console.log("triggered save token");
        // 900 seconds = 15 minutes
        const expiresAt = expiresIn * 1000 + this.#getEpochMilliseconds();
        localStorage.setItem(AUTH_TOKEN_NAME, token);
        localStorage.setItem(AUTH_TOKEN_EXPIRES_AT, `${expiresAt}`);
    }

    deleteToken() {
        localStorage.removeItem(AUTH_TOKEN_NAME);
        localStorage.removeItem(AUTH_TOKEN_EXPIRES_AT);
    }

    isAuthenticated()  {
        if (this.getToken() !== undefined && !this.#isExpired()) {
            return true;
        } else {
            this.deleteToken();
            return false;
        }
    }

    getToken() {
        const token = localStorage.getItem(AUTH_TOKEN_NAME);
        if (token && !this.#isExpired()) {
            return token;
        } else {
            return undefined;
        }
    }

    #getEpochMilliseconds() {
        return new Date().getTime();
    }

    #isExpired()  {
        const expiresAt = localStorage.getItem(AUTH_TOKEN_EXPIRES_AT);

        if (expiresAt) {
            return this.#getEpochMilliseconds() >= parseInt(expiresAt);
        } else {
            return true;
        }
    }
}

export const tokenStorage = new TokenStorage();