import React from 'react';
import {createRoot} from 'react-dom/client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {auth0Options} from './AuthProvider/auth0config'
import {FluentProvider, webLightTheme} from '@fluentui/react-components';
import {AuthProvider} from './AuthProvider/AuthContext';
import {Login} from './Components/Login';

import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store';
import {tokenStorage} from "./AuthProvider/TokenStorage";

const root = createRoot(document.getElementById('root'));

const AppWrapper = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <AuthProvider>
            <FluentProvider theme={webLightTheme}>
                {tokenStorage.isAuthenticated() || isAuthenticated ? <App /> : <Login /> }
            </FluentProvider>
        </AuthProvider>
    );
};

root.render(
    <Auth0Provider
        domain={auth0Options.domain}
        clientId={auth0Options.client_id}
        redirectUri={auth0Options.redirect_uri}
        audience={auth0Options.audience}
        useRefreshTokens={auth0Options.useRefreshTokens}
        cacheLocation={auth0Options.cacheLocation}
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
    >
        <Provider store={store}>
            <AppWrapper />
        </Provider>
    </Auth0Provider>,
);